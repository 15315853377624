import * as Sentry from "@sentry/react";
// import SentryRRWeb from "@sentry/rrweb";
// import { Integration } from "@sentry/types";
import { App } from "components/App";
import { createRoot } from "react-dom/client";
import { appConfig } from "./appConfig";
import { ELEMENT_IDS } from "./constants";

const root = createRoot(
  document.getElementById(ELEMENT_IDS.REACT_ROOT) as HTMLElement
);

if (appConfig.sentry.dsn) {
  Sentry.init({
    dsn: appConfig.sentry.dsn,
    integrations: (defaultIntegrations) =>
      defaultIntegrations.filter(
        (integration) => integration.name !== "BaggageHeaderIntegration"
      ),
    tracesSampleRate: 0.2,
    environment: appConfig.sentry.environment
  });
}

root.render(<App />);
