import QuotasIcon from "@mui/icons-material/AppRegistrationSharp";
import EditIcon from "@mui/icons-material/Edit";
import AdminPanelIcon from "@mui/icons-material/Groups";
import PasswordIcon from "@mui/icons-material/Key";
import DisableUserIcon from "@mui/icons-material/ManageAccountsRounded";
import FA from "@mui/icons-material/PhonelinkLock";
import { Button, IconButton, Tab, Tabs, Tooltip } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { DatePicker } from "@mui/x-date-pickers";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import { FormDialog, selectOptionSchema } from "components/common/FormDialog";
import {
  FIELD_TYPES,
  FormDialogProps,
  SelectOption
} from "components/common/FormDialog/types";
import { Head } from "components/common/Head";
import { Loader } from "components/common/Loader";
import { Menu } from "components/common/Menu";
import { Table } from "components/common/Table";
import {
  TABLE_SORTING_TYPES,
  TableColumn,
  TableMenuItem,
  TableRowActionsMenuItem
} from "components/common/Table/types";
import { addDays, startOfMonth, sub, subDays } from "date-fns";
import { useMount } from "hooks/useMount";
import { usePrevious } from "hooks/usePrevious";
import { useUnmount } from "hooks/useUnmount";
import { adminRoleSelector } from "modules/auth/selectors";
import * as billingActions from "modules/billing/actions";
import {
  isUserBillingDataLoadingSelector,
  tableOrganizationsBillingDataSelector,
  tableOrganizationsTotalBillingDataSelector,
  userBillingDataSelector
} from "modules/billing/selectors";
import { TableOrganizationBillingData } from "modules/billing/types";
import * as enterprisesActions from "modules/enterprises/actions";
import {
  isAdminAddingToOrganizationSelector,
  isAdminRemovingFromOrganizationSelector,
  isOrganizationCreatingSelector,
  isOrganizationDeletingSelector,
  isOrganizationOwnerChangingSelector,
  isOrganizationUpdatingSelector
} from "modules/enterprises/selectors";
import * as pollingActions from "modules/polling/actions";
import * as usersActions from "modules/users/actions";
import {
  isUserInfoUpdatingSelector,
  isUserModifyingEnablingSelector,
  userInfoSelector,
  userOrganizationsSelector
} from "modules/users/selectors";
import { Organization } from "modules/users/types";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { formatDate } from "utils/formatDate";
import { generateSearchString } from "utils/generateSearchString";
import { getFullName } from "utils/getFullName";
import { getSelectOption } from "utils/getSelectOption";
import { validateName } from "utils/validateName";
import { array, number, ref, string } from "yup";
import { appConfig } from "../../appConfig";
import {
  ADMIN_ROLES,
  DATE_FORMATS,
  DEFAULT_CURRENCY,
  ENTITY_NAME_LENGTH,
  ERROR_MESSAGES,
  REGEX,
  ROUTES
} from "../../constants";
import * as s from "./styles";
import { DATE_TYPES, DIALOG_TYPES, TABS } from "./types";

const POLL_ID_PREFIX = "USER";
const POLL_IDS = {
  user: "USER",
  userOrganizations: "USER_ORGS",
  userBillingData: "USER_BILLING"
};
const CURRENCY_SELECT_ID = "currency";

const billingTableColumns: TableColumn<TableOrganizationBillingData>[] = [
  { key: "name", label: "Organization Name" },
  { key: "status", label: "Status" }
];

const userOrganizationsTableColumns: TableColumn<Organization>[] = [
  { key: "name", label: "Name" },
  { key: "id", label: "ID" },
  { key: "role", label: "User Role" },
  {
    key: "project_number_limit",
    label: "Projects limit",
    sortingType: TABLE_SORTING_TYPES.NUMBER
  }
];

export const USER_TAB_TITLES: { [key in TABS]: string } = {
  [TABS.USER_INFO]: "User Info",
  [TABS.BILLING]: "Billing",
  [TABS.ORGANIZATIONS]: "Organizations"
};

export const User: FC = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const matchParams = useParams<{
    userId: string;
  }>();

  const adminRole = useSelector(adminRoleSelector);
  const isUserManagementAllowed = adminRole?.some((role) =>
    role.includes(ADMIN_ROLES.USER_MANAGEMENT)
  );
  const isBillingManagementAllowed = adminRole?.some((role) =>
    role.includes(ADMIN_ROLES.BILLING)
  );
  const isAdminRolesManagementAllowed = adminRole?.some((role) =>
    role.includes(ADMIN_ROLES.ADMIN_ROLES_MANAGEMENT)
  );

  const userInfo = useSelector(userInfoSelector);
  const userName = getFullName(userInfo?.firstName, userInfo?.lastName);

  const userOrganizations = useSelector(userOrganizationsSelector);
  const isUserInfoUpdating = useSelector(isUserInfoUpdatingSelector);
  const isUserModifyingEnabling = useSelector(isUserModifyingEnablingSelector);
  const isOrganizationCreating = useSelector(isOrganizationCreatingSelector);
  const isOrganizationUpdating = useSelector(isOrganizationUpdatingSelector);
  const isOrganizationDeleting = useSelector(isOrganizationDeletingSelector);
  const isAdminAddingToOrganization = useSelector(
    isAdminAddingToOrganizationSelector
  );
  const isAdminRemovingFromOrganization = useSelector(
    isAdminRemovingFromOrganizationSelector
  );
  const isOrganizationsOwnerChanging = useSelector(
    isOrganizationOwnerChangingSelector
  );

  const billingData = useSelector(userBillingDataSelector);
  const isUserBillingDataLoading = useSelector(
    isUserBillingDataLoadingSelector
  );
  const tableBillingData = useSelector(tableOrganizationsBillingDataSelector);
  const tableBillingDataTotal = useSelector(
    tableOrganizationsTotalBillingDataSelector
  );

  const isOperationInProgress =
    isOrganizationCreating ||
    isOrganizationDeleting ||
    isOrganizationUpdating ||
    isAdminAddingToOrganization ||
    isAdminRemovingFromOrganization ||
    isOrganizationsOwnerChanging ||
    isUserModifyingEnabling ||
    isUserInfoUpdating;
  const previousIsOperationInProgress = usePrevious(isOperationInProgress);

  const userTypesOptions = (
    appConfig.userTypes
      ? [
          ...appConfig.userTypes.split(";").map((type) => ({
            label: type,
            value: type
          }))
        ]
      : []
  ).map((type) => getSelectOption(type, "label", "value"));

  const title = userInfo ? userName : undefined;
  const breadcrumbs: Breadcrumb[] = [
    { text: "Users", url: ROUTES.USERS },
    {
      text: userName,
      url: generatePath(ROUTES.USER, {
        userId: matchParams.userId
      })
    }
  ];

  const TAB_TITLES: { [key in TABS]: { title: string; disabled: boolean } } =
    useMemo(
      () => ({
        [TABS.USER_INFO]: {
          title: "User Info",
          disabled: false
        },
        [TABS.BILLING]: {
          title: "Billing",
          disabled: !isBillingManagementAllowed
        },
        [TABS.ORGANIZATIONS]: {
          title: "Organizations",
          disabled: !isUserManagementAllowed
        }
      }),
      [isBillingManagementAllowed, isUserManagementAllowed]
    );

  const activeTabIndexFromParam = Object.keys(TAB_TITLES).find(
    (key) =>
      USER_TAB_TITLES[key] &&
      String(USER_TAB_TITLES[key]).toLowerCase() ===
        new URLSearchParams(location.search).get("tab")
  );
  const [activeTabIndex, setActiveTabIndex] = useState(
    Number(activeTabIndexFromParam || TABS.USER_INFO) as TABS
  );
  const previousActiveTabIndex = usePrevious(activeTabIndex);

  const handleChangeTab = useCallback((e, value: number) => {
    setActiveTabIndex(value);
  }, []);

  const [currency, setCurrency] = useState<string>(DEFAULT_CURRENCY);
  const currencies = useMemo(
    () => (billingData ? Object.keys(billingData.costs) : []),
    [billingData]
  );
  const handleCurrencyChange = useCallback((event: SelectChangeEvent) => {
    setCurrency(event.target.value);
  }, []);
  const currentDate = new Date();
  const minDate = sub(currentDate, { years: 1 });
  const queryParams = new URLSearchParams(location.search);
  const [startDate, setStartDate] = useState<string | null>(
    queryParams.get("start-date") ||
      formatDate(startOfMonth(currentDate), DATE_FORMATS.ISO_DATE)
  );
  const [endDate, setEndDate] = useState<string | null>(
    queryParams.get("end-date") ||
      formatDate(currentDate, DATE_FORMATS.ISO_DATE)
  );
  const previousStartDate = usePrevious(startDate);
  const previousEndDate = usePrevious(endDate);
  const handleDateChange = useCallback(
    (dateType: DATE_TYPES) => (date: Date | null) => {
      const dateValue = date ? formatDate(date, DATE_FORMATS.ISO_DATE) : date;
      switch (dateType) {
        case DATE_TYPES.START:
          setStartDate(dateValue);
          break;
        case DATE_TYPES.END:
          setEndDate(dateValue);
      }
    },
    []
  );

  const generateBillingTableItemURL = useCallback(
    (id: string) =>
      `${generatePath(ROUTES.BILLING_ORGANIZATION, {
        organizationId: id,
        userId: matchParams.userId
      })}${
        startDate && endDate
          ? `?${generateSearchString({
              "start-date": startDate,
              "end-date": endDate
            })}`
          : ""
      }`,
    [matchParams.userId, startDate, endDate]
  );

  const generateOrganizationsTableItemURL = useCallback(
    (id: string) => generatePath(ROUTES.ORGANIZATION, { organizationId: id }),
    []
  );

  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [dialog, setDialog] = useState<{
    isOpened: boolean;
    type: DIALOG_TYPES;
  }>({ type: DIALOG_TYPES.SET_PASSWORD, isOpened: false });

  const handleCloseDialog = useCallback(() => {
    setDialog({
      ...dialog,
      isOpened: false
    });
  }, [dialog]);

  const [isEditUserInfoMenuOpen, setIsEditUserInfoMenuOpen] = useState(false);

  const handleDialogOpen = useCallback(
    (dialogType: DIALOG_TYPES, id?: string) => {
      if (id) setSelectedItemId(id);
      setDialog({
        type: dialogType,
        isOpened: true
      });
      if (isEditUserInfoMenuOpen) setIsEditUserInfoMenuOpen(false);
    },
    [isEditUserInfoMenuOpen]
  );

  const editUserInfoButtonRef = useRef<HTMLButtonElement | null>(null);

  const handleEditUserInfoMenuClose = useCallback(() => {
    setIsEditUserInfoMenuOpen(false);
  }, []);

  const handleEditUserInfoMenuOpen = () => {
    setIsEditUserInfoMenuOpen(true);
  };

  const headerMenuItems: TableMenuItem[] = [
    {
      title: "Manage 2FA",
      icon: <FA />,
      color: "inherit",
      onClick: userInfo?.is_otp_required
        ? () => handleDialogOpen(DIALOG_TYPES.DISABLE_2FA)
        : () => handleDialogOpen(DIALOG_TYPES.ENABLE_2FA)
    },
    {
      title: "Edit user info and type",
      icon: <EditIcon />,
      color: "inherit"
    },
    {
      title: "Change organizations quotas",
      icon: <QuotasIcon />,
      color: "inherit",
      onClick: () => handleDialogOpen(DIALOG_TYPES.CHANGE_QRG_QUOTAS)
    },
    {
      title: "Set password",
      icon: <PasswordIcon />,
      color: "inherit",
      onClick: () => handleDialogOpen(DIALOG_TYPES.SET_PASSWORD)
    },
    ...(isAdminRolesManagementAllowed
      ? ([
          {
            title: "Set admin roles",
            icon: <AdminPanelIcon />,
            color: "inherit",
            onClick: () => handleDialogOpen(DIALOG_TYPES.SET_ADMIN_ROLES)
          }
        ] as TableMenuItem[])
      : ([
          {
            title: "No permissions to set admin roles",
            icon: <AdminPanelIcon />,
            color: "default",
            disabled: true
          }
        ] as TableMenuItem[])),
    {
      title: userInfo?.is_enabled ? "Disable user" : "Enable user",
      icon: <DisableUserIcon />,
      color: "inherit",
      onClick: userInfo?.is_enabled
        ? () => handleDialogOpen(DIALOG_TYPES.DISABLE_USER)
        : () => handleDialogOpen(DIALOG_TYPES.ENABLE_USER)
    }
  ];

  const userOrganizationsTableActions: TableRowActionsMenuItem<Organization>[] =
    [
      {
        label: "Edit",
        handler: (id) => handleDialogOpen(DIALOG_TYPES.EDIT_ORGANIZATION, id)
      },
      {
        label: "Add Administrator",
        handler: (id) => handleDialogOpen(DIALOG_TYPES.ADD_ADMIN, id)
      },
      {
        label: "Remove Administrator",
        handler: (id) => handleDialogOpen(DIALOG_TYPES.REMOVE_ADMIN, id)
      },
      {
        label: "Change Ownership",
        handler: (id) => handleDialogOpen(DIALOG_TYPES.CHANGE_OWNERSHIP, id)
      },
      {
        label: "Delete",
        handler: (id) => handleDialogOpen(DIALOG_TYPES.DELETE_ORGANIZATION, id)
      }
    ];

  const handleConfirmSetPasswdButtonClick = useCallback(
    (data: {
      password: string;
      confirm_password: string;
      change_password_on_next_login: boolean;
    }) => {
      dispatch(
        usersActions.setUsersPassword.started({
          id: matchParams.userId!,
          creds_temporary: data.change_password_on_next_login,
          creds_password: data.password
        })
      );
      handleCloseDialog();
    },
    [dispatch, matchParams.userId, handleCloseDialog]
  );

  const handleConfirmEditUserInfo = useCallback(
    (data: {
      first_name: string;
      last_name: string;
      company_name: string;
      tax_number: string;
      country: string;
      locality: string;
      mobile: string;
      postal_code: string;
      region: string;
      street: string;
    }) => {
      if (userInfo) {
        dispatch(
          usersActions.updateUserInfo.started({
            id: userInfo?.id,
            data: {
              first_name: data.first_name,
              last_name: data.last_name,
              attributes: {
                company_name: data.company_name,
                tax_number: data.tax_number,
                country: data.country,
                locality: data.locality,
                mobile: data.mobile,
                postal_code: data.postal_code,
                region: data.region,
                street: data.street
              }
            }
          })
        );
      }
      handleCloseDialog();
    },
    [dispatch, handleCloseDialog, userInfo]
  );

  const handleConfirmModifyUserType = useCallback(
    (data: { user_type: SelectOption; trial_days?: number }) => {
      dispatch(
        usersActions.updateUserInfo.started({
          id: matchParams.userId!,
          data: {
            trial_days: data.trial_days,
            attributes: { user_type: data.user_type.value }
          }
        })
      );
      handleCloseDialog();
    },
    [dispatch, handleCloseDialog, matchParams.userId]
  );

  const handleConfirmModifyTrialPeriod = useCallback(
    (data: { trial_days: number }) => {
      dispatch(
        usersActions.updateUserInfo.started({
          id: matchParams.userId!,
          data: { trial_days: data.trial_days }
        })
      );
      handleCloseDialog();
    },
    [dispatch, matchParams.userId, handleCloseDialog]
  );

  const handleConfirmDisableUserButtonClick = useCallback(() => {
    dispatch(
      usersActions.modifyUserEnabling.started({
        id: matchParams.userId!,
        enabling_status: "false"
      })
    );
    handleCloseDialog();
  }, [dispatch, matchParams.userId, handleCloseDialog]);

  const handleConfirmEnableUserButtonClick = useCallback(() => {
    dispatch(
      usersActions.modifyUserEnabling.started({
        id: matchParams.userId!,
        enabling_status: "true"
      })
    );
    handleCloseDialog();
  }, [dispatch, matchParams.userId, handleCloseDialog]);

  const handleConfirmSetAdminRoles = useCallback(
    (data: { admin_roles: SelectOption[] }) => {
      dispatch(
        usersActions.updateAdminRoles.started({
          id: matchParams.userId!,
          data: {
            attributes: {
              admin_roles: data.admin_roles.map((role) => role.value)
            }
          }
        })
      );
      handleCloseDialog();
    },
    [dispatch, handleCloseDialog, matchParams.userId]
  );

  const handleConfirmChangeOrgQuotas = useCallback(
    (data: { allowed_org_number: number }) => {
      if (userInfo) {
        dispatch(
          usersActions.updateUserInfo.started({
            id: userInfo?.id,
            data: {
              attributes: {
                allowed_org_number: data.allowed_org_number
              }
            }
          })
        );
      }
      handleCloseDialog();
    },
    [dispatch, handleCloseDialog, userInfo]
  );

  const handleConfirmEnable2FA = useCallback(() => {
    if (userInfo) {
      dispatch(
        usersActions.updateUserInfo.started({
          id: userInfo?.id,
          data: {
            is_otp_required: true
          }
        })
      );
    }
    handleCloseDialog();
  }, [dispatch, handleCloseDialog, userInfo]);

  const handleConfirmDisable2FA = useCallback(() => {
    if (userInfo) {
      dispatch(
        usersActions.updateUserInfo.started({
          id: userInfo?.id,
          data: {
            is_otp_required: false
          }
        })
      );
    }
    handleCloseDialog();
  }, [dispatch, handleCloseDialog, userInfo]);

  const handleConfirmCreateOrganization = useCallback(
    (data: { name: string }) => {
      if (userInfo) {
        dispatch(
          enterprisesActions.createOrganization.started({
            data: {
              org_name: data.name,
              owner_email: userInfo.email
            }
          })
        );
        handleCloseDialog();
      }
    },
    [dispatch, handleCloseDialog, userInfo]
  );

  const handleConfirmChangeOrganizationOwner = useCallback(
    (data: { user_email: string }) => {
      if (selectedItemId) {
        dispatch(
          enterprisesActions.changeOrganizationOwner.started({
            "org-id": selectedItemId,
            "user-email": data.user_email.toLowerCase()
          })
        );
      }
      handleCloseDialog();
    },
    [dispatch, selectedItemId, handleCloseDialog]
  );

  const handleConfirmAddAdminToOrganization = useCallback(
    (data: { user_email: string }) => {
      if (selectedItemId) {
        dispatch(
          enterprisesActions.addAdminToOrganization.started({
            "org-id": selectedItemId,
            "user-email": data.user_email.toLowerCase()
          })
        );
      }
      handleCloseDialog();
    },
    [dispatch, selectedItemId, handleCloseDialog]
  );

  const handleConfirmRemoveAdminFromOrganization = useCallback(
    (data: { user_id: string }) => {
      if (selectedItemId) {
        dispatch(
          enterprisesActions.removeAdminFromOrganization.started({
            "org-id": selectedItemId,
            "user-id": data.user_id
          })
        );
      }
      handleCloseDialog();
    },
    [dispatch, selectedItemId, handleCloseDialog]
  );

  const handleConfirmEditOrganization = useCallback(
    (data: { name: string; project_number_limit: string }) => {
      if (selectedItemId) {
        dispatch(
          enterprisesActions.updateOrganization.started({
            id: selectedItemId,
            data: {
              name: data.name,
              project_number_limit: Number(data.project_number_limit)
            }
          })
        );
      }
      handleCloseDialog();
    },
    [dispatch, selectedItemId, handleCloseDialog]
  );

  const handleConfirmDeleteOrganization = useCallback(() => {
    if (selectedItemId) {
      dispatch(
        enterprisesActions.deleteOrganization.started({
          id: selectedItemId
        })
      );
    }
    handleCloseDialog();
  }, [dispatch, selectedItemId, handleCloseDialog]);

  const registrationDate = userInfo
    ? formatDate(new Date(userInfo.registrationDate), DATE_FORMATS.DATETIME)
    : undefined;

  const previousSelectedItemId = usePrevious(selectedItemId);
  const currentItemId = selectedItemId
    ? selectedItemId
    : previousSelectedItemId;
  const currentOrganizationName = userOrganizations?.find(
    (organization) => organization.id === currentItemId
  )?.name;

  const adminRolesAsSelectionOption = Object.values(ADMIN_ROLES)
    .map((role) => ({
      label: role,
      value: role
    }))
    .map((role) => getSelectOption(role, "label", "value"));

  const currentAdminRolesAsSelectionOption = adminRolesAsSelectionOption.filter(
    (role) => userInfo?.admin_roles?.includes(role.value)
  );

  const tabContent = [
    <>
      {userInfo ? (
        <>
          <s.InfoDescription>Registered: {registrationDate}</s.InfoDescription>
          <s.StyledPaper variant="outlined">
            <s.DetailsRow>
              <s.DetailsBlock>
                <s.InfoTitle variant={"h5"} component={"h6"}>
                  Contact
                </s.InfoTitle>
                <s.SummaryRow>
                  <s.DetailsTitle>E-mail: </s.DetailsTitle>
                  <s.StyledLink href={`mailto:${userInfo.email}`}>
                    {userInfo.email}
                  </s.StyledLink>
                </s.SummaryRow>
                <s.SummaryRow>
                  <s.DetailsTitle>Mobile: </s.DetailsTitle>
                  <s.StyledLink href={`tel:${userInfo.mobile}`}>
                    {userInfo.mobile}
                  </s.StyledLink>
                </s.SummaryRow>
              </s.DetailsBlock>
              <s.DetailsBlock>
                <s.InfoTitle variant={"h5"} component={"h6"}>
                  Address
                </s.InfoTitle>
                <s.SummaryRow>
                  <s.DetailsTitle>Street: </s.DetailsTitle>
                  <s.DetailsInfo>{userInfo.street}</s.DetailsInfo>
                </s.SummaryRow>
                <s.SummaryRow>
                  <s.DetailsTitle>City or Locality: </s.DetailsTitle>
                  <s.DetailsInfo>{userInfo.locality}</s.DetailsInfo>
                </s.SummaryRow>
                <s.SummaryRow>
                  <s.DetailsTitle>State, Province, or Region: </s.DetailsTitle>
                  <s.DetailsInfo>{userInfo.region}</s.DetailsInfo>
                </s.SummaryRow>
                <s.SummaryRow>
                  <s.DetailsTitle>Zip or Postal code: </s.DetailsTitle>
                  <s.DetailsInfo>{userInfo.postalCode}</s.DetailsInfo>
                </s.SummaryRow>
                <s.SummaryRow>
                  <s.DetailsTitle>Country: </s.DetailsTitle>
                  <s.DetailsInfo>{userInfo.country}</s.DetailsInfo>
                </s.SummaryRow>
              </s.DetailsBlock>
              <s.DetailsBlock>
                <s.InfoTitle variant={"h5"} component={"h6"}>
                  Company
                </s.InfoTitle>
                <s.SummaryRow>
                  <s.DetailsTitle>Company name: </s.DetailsTitle>
                  <s.DetailsInfo>{userInfo.companyName}</s.DetailsInfo>
                </s.SummaryRow>
                <s.SummaryRow>
                  <s.DetailsTitle>Company tax number: </s.DetailsTitle>
                  <s.DetailsInfo>{userInfo.companyTaxNumber}</s.DetailsInfo>
                </s.SummaryRow>
              </s.DetailsBlock>
            </s.DetailsRow>
          </s.StyledPaper>
        </>
      ) : (
        <Loader text={"Loading user information..."} />
      )}
    </>,
    <>
      <>
        <s.Description>
          Billing data is available for previous 12 months
        </s.Description>
        <Table
          isSearchEnabled={true}
          isSortingEnabled={true}
          rows={tableBillingData || []}
          itemLink={{
            column: "name",
            getURL: generateBillingTableItemURL
          }}
          isLoading={isUserBillingDataLoading}
          columns={[
            ...billingTableColumns,
            {
              key: `cost-${currency}`,
              label: "Cost",
              sortingType: TABLE_SORTING_TYPES.NUMBER
            }
          ]}
          totalRow={tableBillingDataTotal || undefined}
          toolbarItems={
            <>
              <DatePicker
                label={"Start date"}
                onChange={handleDateChange(DATE_TYPES.START)}
                value={startDate ? new Date(startDate) : null}
                format={DATE_FORMATS.DATE}
                minDate={minDate}
                // maxDate={endDate ? subDays(new Date(endDate), 1) : undefined}
                maxDate={endDate ? new Date(endDate) : undefined}
                disableFuture={true}
                sx={{ marginRight: "10px" }}
                slotProps={{
                  textField: {
                    InputProps: { size: "small" }
                  }
                }}
              />
              <DatePicker
                label={"End date"}
                onChange={handleDateChange(DATE_TYPES.END)}
                value={endDate ? new Date(endDate) : null}
                format={DATE_FORMATS.DATE}
                // minDate={startDate ? addDays(new Date(startDate), 1) : minDate}
                minDate={startDate ? new Date(startDate) : minDate}
                disableFuture={true}
                sx={{ marginRight: "10px" }}
                slotProps={{
                  textField: {
                    InputProps: { size: "small" }
                  }
                }}
              />
              {currencies.length > 0 && (
                <s.CurrencyFormControl>
                  <InputLabel id={CURRENCY_SELECT_ID}>Currency</InputLabel>
                  <Select
                    label={"Currency"}
                    labelId={CURRENCY_SELECT_ID}
                    value={currency}
                    onChange={handleCurrencyChange}
                    size={"small"}
                  >
                    {currencies.map((currency) => (
                      <MenuItem key={currency} value={currency}>
                        {currency.toUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                </s.CurrencyFormControl>
              )}
            </>
          }
        />
      </>
    </>,
    <>
      <Table<Organization>
        isSearchEnabled={true}
        isSortingEnabled={true}
        isLoading={!userOrganizations}
        key={"userOrganizationsTable"}
        rows={userOrganizations || []}
        columns={userOrganizationsTableColumns}
        actions={userOrganizationsTableActions}
        itemLink={{
          column: "name",
          getURL: generateOrganizationsTableItemURL
        }}
        toolbarItems={
          <Button
            onClick={() => handleDialogOpen(DIALOG_TYPES.CREATE_ORGANIZATION)}
            variant={"contained"}
            disabled={!userOrganizations}
          >
            Create organization
          </Button>
        }
      />
    </>
  ];

  const dialogProps: {
    [key in DIALOG_TYPES]: Omit<FormDialogProps, "isOpened" | "onCancel">;
  } = {
    [DIALOG_TYPES.SET_PASSWORD]: {
      onConfirm: handleConfirmSetPasswdButtonClick,
      title: "Set password to the user",
      confirmButtonLabel: "Set",
      fields: [
        {
          name: "password",
          type: FIELD_TYPES.PASSWORD,
          label: "Password",
          autocomplete: "new-password",
          rules: string()
            .required()
            .matches(REGEX.PASSWORD, ERROR_MESSAGES.PASSWORD)
        },
        {
          name: "confirm_password",
          type: FIELD_TYPES.PASSWORD,
          label: "Confirm password",
          autocomplete: "new-password",
          rules: string().oneOf(
            [ref("password")],
            ERROR_MESSAGES.PASSWORDS_MUST_MATCH
          )
        },
        {
          name: "change_password_on_next_login",
          type: FIELD_TYPES.CHECKBOX,
          label: "Change password on next login",
          helperText: "Change password on next login"
        }
      ]
    },
    [DIALOG_TYPES.EDIT_USER_INFO]: {
      onConfirm: handleConfirmEditUserInfo,
      title: "Edit user info",
      confirmButtonLabel: "Edit",
      fields: [
        {
          name: "first_name",
          type: FIELD_TYPES.TEXT,
          label: "First name",
          defaultValue: userInfo?.firstName || "",
          rules: string().required()
        },
        {
          name: "last_name",
          type: FIELD_TYPES.TEXT,
          label: "Last name",
          defaultValue: userInfo?.lastName || "",
          rules: string().required()
        },
        {
          name: "mobile",
          type: FIELD_TYPES.TEXT,
          label: "Mobile Number",
          defaultValue: userInfo?.mobile || "",
          rules: string()
        },
        {
          name: "street",
          type: FIELD_TYPES.TEXT,
          label: "Street",
          defaultValue: userInfo?.street || "",
          rules: string()
        },
        {
          name: "locality",
          type: FIELD_TYPES.TEXT,
          label: "City or Locality",
          defaultValue: userInfo?.locality || "",
          rules: string()
        },
        {
          name: "region",
          type: FIELD_TYPES.TEXT,
          label: "State, Province, or Region",
          defaultValue: userInfo?.region || "",
          rules: string()
        },
        {
          name: "country",
          type: FIELD_TYPES.TEXT,
          label: "Country",
          defaultValue: userInfo?.country || "",
          rules: string()
        },
        {
          name: "postal_code",
          type: FIELD_TYPES.TEXT,
          label: "Zip or Postal code",
          defaultValue: userInfo?.postalCode || "",
          rules: string()
        },
        {
          name: "company_name",
          type: FIELD_TYPES.TEXT,
          label: "Company name",
          defaultValue: userInfo?.companyName || "",
          rules: string()
        },
        {
          name: "tax_number",
          type: FIELD_TYPES.TEXT,
          label: "Company Tax number",
          defaultValue: userInfo?.companyTaxNumber || "",
          rules: string()
        }
      ]
    },
    [DIALOG_TYPES.MODIFY_USER_TYPE]: {
      onConfirm: handleConfirmModifyUserType,
      title: "Modify user type",
      confirmButtonLabel: "Modify",
      fields: [
        {
          name: "user_type",
          type: FIELD_TYPES.SELECT,
          label: "User Type",
          defaultValue: userTypesOptions.find(
            (option) => option.value === userInfo?.user_type
          ),
          options: userTypesOptions,
          rules: selectOptionSchema.required()
        },
        {
          name: "trial_days",
          type: FIELD_TYPES.NUMBER,
          label: "Trial Days",
          min: 0,
          max: 365,
          suffix: "day",
          defaultValue: () => {
            const trialDays = userInfo?.trial_days;
            return trialDays && trialDays >= 0 ? trialDays : 0;
          },
          rules: number()
            .integer()
            .min(0, ERROR_MESSAGES.TRIAL_DAYS)
            .max(365, ERROR_MESSAGES.TRIAL_DAYS),
          isHidden: (fieldValues) => {
            return (
              !fieldValues.user_type ||
              (fieldValues.user_type &&
                (fieldValues.user_type as SelectOption).value !== "trial")
            );
          }
        }
      ]
    },
    [DIALOG_TYPES.MODIFY_TRIAL_PERIOD]: {
      onConfirm: handleConfirmModifyTrialPeriod,
      title: "Modify user trial period",
      confirmButtonLabel: "Modify",
      fields: [
        {
          name: "trial_days",
          type: FIELD_TYPES.NUMBER,
          label: "Trial Days",
          min: 0,
          max: 365,
          suffix: "day",
          defaultValue: () => {
            const trialDays = userInfo?.trial_days;
            return trialDays && trialDays >= 0 ? trialDays : 0;
          },
          rules: number()
            .integer()
            .required()
            .min(0, ERROR_MESSAGES.TRIAL_DAYS)
            .max(365, ERROR_MESSAGES.TRIAL_DAYS)
        }
      ]
    },
    [DIALOG_TYPES.ENABLE_2FA]: {
      onConfirm: handleConfirmEnable2FA,
      title: `Are you sure you want to force "${
        userName ?? "selected"
      }" user to configure 2FA?`,
      confirmButtonLabel: "Force"
    },
    [DIALOG_TYPES.DISABLE_2FA]: {
      onConfirm: handleConfirmDisable2FA,
      title: `Are you sure you want to erase 2FA settings for the "${
        userName ?? "selected"
      }" user?`,
      confirmButtonLabel: "Erase"
    },
    [DIALOG_TYPES.DISABLE_USER]: {
      onConfirm: handleConfirmDisableUserButtonClick,
      title: `Are you sure you want to disable "${
        userName ?? "selected"
      }" user?`,
      confirmButtonLabel: "Disable"
    },
    [DIALOG_TYPES.ENABLE_USER]: {
      onConfirm: handleConfirmEnableUserButtonClick,
      title: `Are you sure you want to enable "${
        userName ?? "selected"
      }" user?`,
      confirmButtonLabel: "Enable"
    },
    [DIALOG_TYPES.SET_ADMIN_ROLES]: {
      onConfirm: handleConfirmSetAdminRoles,
      title: "Set admin roles",
      confirmButtonLabel: "Set",
      isLocked: false,
      fields: [
        {
          name: "admin_roles",
          type: FIELD_TYPES.MULTISELECT,
          label: "Admin Roles",
          options: adminRolesAsSelectionOption,
          defaultValue: currentAdminRolesAsSelectionOption || [],
          rules: array(selectOptionSchema)
        },
        {
          name: "info",
          type: FIELD_TYPES.NOTES,
          label: `✍ Allowed roles: user_management, billing, operator, observe, admin_roles_management`
        }
      ]
    },
    [DIALOG_TYPES.CHANGE_QRG_QUOTAS]: {
      onConfirm: handleConfirmChangeOrgQuotas,
      title: "Change organizations quotas",
      confirmButtonLabel: "Edit",
      fields: [
        {
          name: "allowed_org_number",
          type: FIELD_TYPES.NUMBER,
          label: "Organizations number limit",
          defaultValue:
            userInfo?.org_number_limit || appConfig.defaultAllowedOrgNumber,
          // min: appConfig.defaultAllowedOrgNumber,
          rules: number()
            .integer()
            .required()
            .min(0, ERROR_MESSAGES.ORGANIZATIONS_NUMBER)
        }
      ]
    },
    [DIALOG_TYPES.CREATE_ORGANIZATION]: {
      onConfirm: handleConfirmCreateOrganization,
      title: "Create organization",
      confirmButtonLabel: "Create",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(ENTITY_NAME_LENGTH)
            })
            .matches(REGEX.ORGANIZATION_NAME, ERROR_MESSAGES.ORGANIZATION_NAME)
        }
      ]
    },
    [DIALOG_TYPES.EDIT_ORGANIZATION]: {
      onConfirm: handleConfirmEditOrganization,
      title: `Edit organization`,
      confirmButtonLabel: "Edit",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          defaultValue:
            userOrganizations?.find(
              (organization) => organization.id === selectedItemId
            )?.name || "",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(ENTITY_NAME_LENGTH)
            })
            .matches(REGEX.ORGANIZATION_NAME, ERROR_MESSAGES.ORGANIZATION_NAME)
        },
        {
          name: "project_number_limit",
          type: FIELD_TYPES.NUMBER,
          label: "Project number limit",
          defaultValue: (() => {
            const projectLimit = userOrganizations
              ?.find((organization) => organization.id === selectedItemId)
              ?.project_number_limit.toString();
            return projectLimit || appConfig.defaultAllowedProjectNumber;
          })(),
          // min: appConfig.defaultAllowedProjectNumber,
          rules: number()
            .integer()
            .required()
            .min(0, ERROR_MESSAGES.PROJECTS_NUMBER)
        }
      ]
    },
    [DIALOG_TYPES.ADD_ADMIN]: {
      onConfirm: handleConfirmAddAdminToOrganization,
      title: `Add Administrator`,
      confirmButtonLabel: "Add",
      fields: [
        {
          name: "user_email",
          type: FIELD_TYPES.TEXT,
          label: "New Administrator E-mail",
          rules: string()
            .required()
            .matches(REGEX.EMAIL_ADDRESS, ERROR_MESSAGES.EMAIL_ADDRESS)
        },
        {
          name: "notes",
          type: FIELD_TYPES.NOTES,
          label: `✍ Only already registered users can be added`
        }
      ]
    },
    [DIALOG_TYPES.REMOVE_ADMIN]: {
      onConfirm: handleConfirmRemoveAdminFromOrganization,
      title: `Remove Administrator`,
      confirmButtonLabel: "Remove",
      fields: [
        {
          name: "user_id",
          type: FIELD_TYPES.TEXT,
          label: "Administrator ID",
          rules: string()
        },
        {
          name: "info",
          type: FIELD_TYPES.NOTES,
          label: `✍ it is not allowed to delete organization owner`
        }
      ]
    },
    [DIALOG_TYPES.CHANGE_OWNERSHIP]: {
      onConfirm: handleConfirmChangeOrganizationOwner,
      title: `Transfer organization`,
      confirmButtonLabel: "Transfer",
      fields: [
        {
          name: "user_email",
          type: FIELD_TYPES.TEXT,
          label: "New Owner E-mail",
          rules: string()
            .required()
            .matches(REGEX.EMAIL_ADDRESS, ERROR_MESSAGES.EMAIL_ADDRESS)
        },
        {
          name: "info",
          type: FIELD_TYPES.NOTES,
          label: `✍ Organizations can only be transferred to its administrators`
        }
      ]
    },
    [DIALOG_TYPES.DELETE_ORGANIZATION]: {
      onConfirm: handleConfirmDeleteOrganization,
      title: `Are you sure you want to delete "${
        currentOrganizationName ?? "selected"
      }" organization?`,
      confirmButtonLabel: "Delete"
    }
  };

  useMount(() => {
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.user}`,
        action: usersActions.getUserInfo.started({ id: matchParams.userId! })
      })
    );
    if (isBillingManagementAllowed && startDate && endDate) {
      billingActions.getUserBillingData.started({
        startDate,
        endDate,
        userId: matchParams.userId!
      });
    }

    if (isUserManagementAllowed) {
      dispatch(
        usersActions.getUserOrganizations.started({
          id: matchParams.userId!
        })
      );
    }
  });

  useEffect(() => {
    if (previousActiveTabIndex !== activeTabIndex) {
      Object.values([POLL_IDS.userOrganizations]).forEach((id) => {
        dispatch(
          pollingActions.stopPolling({
            id: `${POLL_ID_PREFIX}/${id}`
          })
        );
      });
      let searchParams = {};
      switch (activeTabIndex) {
        case TABS.USER_INFO:
          searchParams = {
            tab: TAB_TITLES[TABS.USER_INFO].title
          };
          // Do nothing because user info is always loaded
          break;

        case TABS.ORGANIZATIONS:
          searchParams = {
            tab: TAB_TITLES[TABS.ORGANIZATIONS].title
          };
          dispatch(
            pollingActions.startPolling({
              id: `${POLL_ID_PREFIX}/${POLL_IDS.userOrganizations}`,
              action: usersActions.getUserOrganizations.started({
                id: matchParams.userId!
              })
            })
          );
          break;

        case TABS.BILLING:
          searchParams = {
            tab: TAB_TITLES[TABS.BILLING].title,
            "start-date": startDate || "",
            "end-date": endDate || ""
          };
          if (!tableBillingData && startDate && endDate) {
            dispatch(
              billingActions.getUserBillingData.started({
                startDate,
                endDate,
                userId: matchParams.userId!
              })
            );
          }
          break;
        default:
          break;
      }
      history({ search: generateSearchString(searchParams) });
    }
  }, [
    dispatch,
    startDate,
    endDate,
    matchParams.userId,
    history,
    activeTabIndex,
    TAB_TITLES,
    previousActiveTabIndex,
    tableBillingData
  ]);

  useEffect(() => {
    if (
      activeTabIndex === TABS.BILLING &&
      startDate &&
      endDate &&
      (startDate !== previousStartDate || endDate !== previousEndDate)
    ) {
      history({
        search: generateSearchString({
          tab: TAB_TITLES[TABS.BILLING].title,
          "start-date": startDate,
          "end-date": endDate
        })
      });
      dispatch(
        billingActions.getUserBillingData.started({
          startDate,
          endDate,
          userId: matchParams.userId!
        })
      );
    }
  }, [
    dispatch,
    startDate,
    endDate,
    matchParams.userId,
    activeTabIndex,
    history,
    TAB_TITLES,
    previousStartDate,
    previousEndDate
  ]);

  useEffect(() => {
    if (previousIsOperationInProgress && !isOperationInProgress) {
      dispatch(
        usersActions.getUserOrganizations.started({
          id: matchParams.userId!
        })
      );
    }
  }, [
    dispatch,
    isOperationInProgress,
    matchParams.userId,
    previousIsOperationInProgress
  ]);

  useUnmount(() => {
    Object.values(POLL_IDS).forEach((id) => {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${id}`
        })
      );
    });
    dispatch(billingActions.clear());
    dispatch(usersActions.clear());
  });

  return (
    <>
      <Head title={title} />
      {userInfo ? (
        <>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <s.SummaryContainer>
            <s.Title variant={"h4"} component={"h2"}>
              User: {title}
            </s.Title>
            {isUserManagementAllowed ? (
              <s.ActionsContainer>
                {headerMenuItems.map((menuItem, index) => (
                  <Tooltip title={menuItem.title} arrow key={index}>
                    {menuItem.title === "Edit user info and type" ? (
                      <span>
                        <IconButton
                          onClick={handleEditUserInfoMenuOpen}
                          ref={editUserInfoButtonRef}
                          color={menuItem.color}
                          disabled={menuItem.disabled}
                        >
                          {menuItem.icon}
                        </IconButton>
                      </span>
                    ) : (
                      <IconButton
                        onClick={() => menuItem.onClick && menuItem.onClick()}
                        color={menuItem.color}
                        disabled={menuItem.disabled}
                      >
                        {menuItem.icon}
                      </IconButton>
                    )}
                  </Tooltip>
                ))}
                <Menu
                  isOpened={isEditUserInfoMenuOpen}
                  onClose={handleEditUserInfoMenuClose}
                  anchorEl={editUserInfoButtonRef.current}
                  items={[
                    {
                      label: "Update user info",
                      onClick: () =>
                        handleDialogOpen(DIALOG_TYPES.EDIT_USER_INFO)
                    },
                    {
                      label: "Modify user type",
                      onClick: () =>
                        handleDialogOpen(DIALOG_TYPES.MODIFY_USER_TYPE)
                    },
                    {
                      label: "Modify user trial period",
                      onClick: () =>
                        handleDialogOpen(DIALOG_TYPES.MODIFY_TRIAL_PERIOD),
                      isDisabled: userInfo.user_type !== "trial"
                    }
                  ]}
                />
              </s.ActionsContainer>
            ) : (
              ""
            )}
          </s.SummaryContainer>
          <s.SummaryRowInfo>
            <s.DetailsTitleMain>ID: </s.DetailsTitleMain>
            <s.DetailsInfoMain>{userInfo.id}</s.DetailsInfoMain>
          </s.SummaryRowInfo>
          <s.SummaryRow>
            <s.DetailsTitleMain>E-mail: </s.DetailsTitleMain>
            <s.DetailsInfoMainColored>
              {userInfo.email}
            </s.DetailsInfoMainColored>
            <s.DetailsTitleMain>Status: </s.DetailsTitleMain>
            {userInfo.is_enabled ? (
              <s.DetailsInfoMainColoredSuccess>
                enabled
              </s.DetailsInfoMainColoredSuccess>
            ) : (
              <s.DetailsInfoMainColoredWarning>
                disabled
              </s.DetailsInfoMainColoredWarning>
            )}
          </s.SummaryRow>

          <s.SummaryRow>
            <s.DetailsTitleMain>2FA: </s.DetailsTitleMain>
            {userInfo.is_otp_required ? (
              <s.DetailsInfoMainColoredSuccess>
                activated
              </s.DetailsInfoMainColoredSuccess>
            ) : (
              <s.DetailsInfoMainColoredWarning>
                deactivated
              </s.DetailsInfoMainColoredWarning>
            )}

            {userInfo.org_number_limit && (
              <>
                <s.DetailsTitleMain>Org Quotas: </s.DetailsTitleMain>
                <s.Tag
                  key={userInfo.org_number_limit}
                  label={userInfo.org_number_limit}
                />
              </>
            )}
          </s.SummaryRow>

          <s.SummaryRow>
            <s.DetailsTitleMain>User Type: </s.DetailsTitleMain>
            <s.DetailsInfoMainColored>
              {userInfo.user_type ? userInfo.user_type : ""}
            </s.DetailsInfoMainColored>

            {userInfo.trial_expiration_date &&
              userInfo.trial_days != undefined && (
                <>
                  <s.DetailsTitleMain>Expiration Date: </s.DetailsTitleMain>
                  {userInfo.trial_days > 0 ? (
                    <Tooltip
                      arrow
                      title={`${userInfo.trial_days} trial days left.`}
                    >
                      {userInfo.trial_days <= 10 ? (
                        <s.DetailsInfoMainColoredWarning>
                          {formatDate(
                            new Date(userInfo.trial_expiration_date),
                            DATE_FORMATS.DATE
                          )}
                        </s.DetailsInfoMainColoredWarning>
                      ) : (
                        <s.DetailsInfoMainColored>
                          {formatDate(
                            new Date(userInfo.trial_expiration_date),
                            DATE_FORMATS.DATE
                          )}
                        </s.DetailsInfoMainColored>
                      )}
                    </Tooltip>
                  ) : (
                    <Tooltip
                      arrow
                      title={
                        userInfo.trial_days === 0
                          ? `trial period expires today`
                          : `trial period expired ${Math.abs(
                              userInfo.trial_days
                            )} days ago`
                      }
                    >
                      <s.DetailsInfoMainColoredDanger>
                        {formatDate(
                          new Date(userInfo.trial_expiration_date),
                          DATE_FORMATS.DATE
                        )}
                      </s.DetailsInfoMainColoredDanger>
                    </Tooltip>
                  )}
                </>
              )}
          </s.SummaryRow>

          {userInfo.admin_roles && userInfo.admin_roles?.length > 0 && (
            <s.SummaryRow>
              <s.DetailsTitleMain>Admin Roles: </s.DetailsTitleMain>
              {userInfo.admin_roles.map((role) => (
                <s.Tag key={role} label={role} />
              ))}
            </s.SummaryRow>
          )}

          <s.TabsContainer>
            <Tabs value={activeTabIndex} onChange={handleChangeTab}>
              {Object.values(TAB_TITLES).map((tab) => (
                <Tab
                  key={tab.title}
                  label={tab.title}
                  disabled={tab.disabled}
                />
              ))}
            </Tabs>
          </s.TabsContainer>
          {tabContent[activeTabIndex]}
          <FormDialog
            isOpened={dialog.isOpened}
            onCancel={handleCloseDialog}
            fields={dialogProps[dialog.type].fields}
            onConfirm={dialogProps[dialog.type].onConfirm}
            title={dialogProps[dialog.type].title}
            confirmButtonLabel={dialogProps[dialog.type].confirmButtonLabel}
          />
        </>
      ) : (
        <Loader text={"Loading data..."} />
      )}
    </>
  );
};
